.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(90vh - 0px);
  width: 100%;
}

.app-chat {
  width: 100%;
  max-width: 600px;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.chat-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  background: linear-gradient(
    106.37deg,
    #111 0%,
    #2b2b2b 15%,
    #444 30%,
    #5c8e2a 60%,
    #7db73a 80%
  );
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-radius: 20px;
}

.logo {
  font-size: 18px;
  color: #eee;
  font-weight: 600;
}

.messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 200px);
  position: relative;
}

.message {
  display: flex;
  margin-bottom: 10px;
}

.message > div {
  max-width: 80%;
  background: #fff;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 12px;
}

.my-message {
  justify-content: flex-end;
}

.other-message {
  justify-content: flex-start;
}

.name {
  font-size: 13px;
  color: #555;
  margin-bottom: 5px;
  font-weight: bold;
}

.text {
  word-wrap: break-word;
}

.timestamp {
  font-size: 11px;
  color: #999;
  margin-top: 5px;
}

.typebox {
  display: flex;
  padding: 10px;
  background: linear-gradient(
    106.37deg,
    #111 0%,
    #2b2b2b 15%,
    #444 30%,
    #5c8e2a 60%,
    #7db73a 80%
  );
  border-top: 1px solid #ccc;
  border-radius: 20px;
}

.typebox form {
  display: flex;
  width: 100%;
}

.typebox input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px 0 0 25px;
  margin-right: 0;
}

.typebox button {
  width: 80px;
  background: #444;
  color: #eee;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
  transition: background 0.3s;
}

.typebox button:hover {
  background: #265a00;
}

/* Personalización del scrollbar */
.messages::-webkit-scrollbar {
  width: 8px;
}

.messages::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}
