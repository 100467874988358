.App {
  background: linear-gradient(
    106.37deg,
    #111 0%,
    #2b2b2b 15%,
    #444 30%,
    #5c8e2a 60%,
    #7db73a 80%
  );
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.glass-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  max-width: 600px;
  width: 100%;
  color: white;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

p {
  font-size: 1.25rem;
}

a {
  font-size: 1.25rem;
  color: #1a1a1a; /* Change link color */
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: #0009b9; /* Change link color on hover */
  text-decoration: underline;
}
