.update {
  padding: 2px;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
  position: relative;
}

.update:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.unread-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}
