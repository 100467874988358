.date-range-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  /* background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  margin-bottom: 0.5rem;
}

.date-picker-wrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.date-picker-wrapper-label {
  display: flex;
  flex-direction: column;
}

.date-picker-label {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  padding: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.date-picker-input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
