.subir-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  width: 55%;
  height: 60%;
  padding: 20px;
  box-shadow: 0 0 5px #ffffff;
  border-radius: 5px;
  background-color: #f4f3f9;
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
}

.card button {
  outline: 0;
  border: 0;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 8px 13px;
  background: #7db73a;
  margin-top: 10px;
}

.card .drag-area {
  flex: 1;
  border-radius: 5px;
  border: 2px dashed #7db73a;
  color: #7db73a;
  font-weight: 500;
  background: #f4f3f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
  text-align: center;
}

.card .drag-area .visible {
  font-size: 18px;
}

.card .cloud-image {
  width: 100px;
  margin-bottom: 10px;
}

.card .select {
  color: #5256ad;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s;
}

.card .select:hover {
  opacity: 0.6s;
}

.card .container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: hidden; /* Prevent scrolling */
  margin-top: 10px;
}

.card .container .image {
  width: 75px;
  margin-right: 5px;
  height: 75px;
  position: relative;
  margin-bottom: 10px;
}

.card .container .image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  object-fit: cover;
}

.card .container .image span {
  position: absolute;
  top: -15px;
  right: 5px;
  font-size: 40px;
  cursor: pointer;
}

.card input,
.card .drag-area .on-drop,
.card .drag-area.dragover .visible {
  display: none;
}

.delete {
  z-index: 999;
  color: #ff0000;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  background-color: #fefefe;
  padding: 2px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  position: relative;
}

.modal-content img {
  width: 100%;
  transition: transform 0.3s ease; /* Smooth transition for zoom */
}

.modal-content img:hover {
  transform: scale(1.4); /* Zoom effect */
}

.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loading-button {
  background-color: #6c757d !important;
  cursor: not-allowed;
}

.success-message {
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin-top: 20px;
}

.disabled-button {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.upload-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.success-message {
  color: #7db73a;
}
