.neon-text {
  color: #a9ff46;
  font-size: 18px;
  text-shadow: 0 0 5px #7db73a, 0 0 10px #7db73a, 0 0 20px #7db73a,
    0 0 40px #7db73a, 0 0 80px #7db73a;
}

.logo-main {
  filter: drop-shadow(0 0 10px rgba(132, 255, 0, 0.3));
}

.glass {
  backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Semi-transparent background color */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for the glass effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  padding: 2rem; /* Padding to match p-8 */
  border-radius: 0.5rem; /* Rounded corners to match rounded-lg */
  width: 100%; /* Full width */
  max-width: 28rem; /* Max width to match max-w-md */
  margin: 1rem auto; /* Center the div with mx-4 and vertical margin */
  border-color: #e5e7eb; /* Border color to match border-gray-200 */
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.animate-shake {
  animation: shake 0.5s;
}

/* NotificationsAdmin.css or global CSS file */
