.Updates {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 460px;
  overflow-y: auto;
  padding: 1rem;
  /* background: white; */
  border-radius: 0.7rem;
}

/* Custom scrollbar styles */
.Updates::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.Updates::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
  border-radius: 10px; /* Rounding the corners of the track */
}

.Updates::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounding the corners of the thumb */
}

.Updates::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

.update {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notification {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-header {
  display: flex;
  flex-wrap: wrap;
  /* gap: 0.2rem; */
  margin-bottom: 0.5rem;
}

.notification-name {
  font-weight: bold;
  color: #333;
  white-space: nowrap;
}

.notification-action {
  color: #555;
}

.notification-time {
  color: #888;
  font-size: 0.9rem;
}

.no-notifications {
  color: red;
  font-weight: bold;
}
