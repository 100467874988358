:root {
  --pink: #a9ff46;
  --activeItem: #7db73a;
  /* --glass: rgba(72, 72, 72, 0.897); */
}

.Sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 1.5rem;
  transition: all 300ms ease;
  height: 100%;
}

.logo {
  display: flex;
  height: 5rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.logo > img {
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
}

.Eslogan {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
  color: white;
  user-select: none;
}

.Eslogan > span {
  color: #a9ff46;
  font-size: 18px;
  text-shadow: 0 0 5px #7db73a, 0 0 10px #7db73a, 0 0 20px #7db73a,
    0 0 40px #7db73a, 0 0 80px #7db73a;
}

/* Menu */
.menu {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-grow: 1;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2.5rem;
  margin-left: 1.5rem;
  position: relative;
  transition: all 300ms ease;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.7rem;
  color: white;
  user-select: none;
}

.menuItem:hover {
  cursor: pointer;
}

.active {
  background: var(--activeItem);
  margin-left: 0;
  white-space: nowrap;
}

.active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--pink);
  margin-right: calc(1rem - 8px);
}

.bars {
  display: none;
}

@media screen and (max-width: 1200px) {
  .menuItem > span {
    display: none;
  }

  .logo {
    display: none;
  }

  .user {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .Sidebar {
    position: fixed;
    z-index: 9;
    background-color: rgba(72, 72, 72, 0.897);
    width: 55%;
    padding-right: 1rem;
    height: 100%;
  }
  .menuItem > span {
    display: block;
  }

  .logo {
    display: flex;
  }

  .menu .menuItem:last-child {
    position: relative;
    margin-top: 2rem;
  }

  .bars {
    display: flex;
    position: fixed;
    top: 2rem;
    z-index: 9;
    background-color: rgba(72, 72, 72, 0.897);
    padding: 10px;
    border-radius: 10px;
  }
}
