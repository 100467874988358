.MuiTableRow-root > * {
  padding: 10px;
}

.MuiTableRow-root.MuiTableRow-head > * {
  font-weight: bold !important;
  color: white;
  background: linear-gradient(rgb(35, 37, 38) 0%, rgb(65, 67, 69) 100%);
}

.Table {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0 1rem 2rem 1rem;
}

.Table td,
th {
  border: none !important;
}

.Table:first-child {
  border-radius: 0.7rem !important;
}

.status {
  padding: 8px;
  border-radius: 9px;
}

.table-container {
  overflow-y: auto;
  position: relative;
  display: block;
  scroll-behavior: smooth;
}

/* Custom scrollbar styles */
.table-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
  border-radius: 10px; /* Rounding the corners of the track */
}

.table-container::-webkit-scrollbar-thumb {
  background: #999; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounding the corners of the thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}
