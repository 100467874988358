/* ModalCanvas.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content-canvas {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  justify-content: center;
  align-items: center;
  max-width: min-content;
  max-height: 100%;
  overflow: auto;
}
