:root {
  --glass: rgba(255, 255, 255, 0.54);
  --pink: #ff919d;
}

.App {
  background: linear-gradient(
    106.37deg,
    #111 0%,
    #2b2b2b 15%,
    #444 30%,
    #5c8e2a 60%,
    #7db73a 80%
  );
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppGlass {
  display: grid;
  height: 97%;
  width: 97%;
  border-radius: 2rem;
  background-color: var(--glass);
  overflow: hidden;
  grid-template-columns: 11rem auto 18rem;
}

/* Media Queries */

/* Pequeños teléfonos móviles */
@media (min-width: 360px) and (max-width: 640px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }

  .AppGlass::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .AppGlass {
    grid-template-columns: 10% auto 16rem;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }
  .AppGlass::-webkit-scrollbar {
    display: none;
  }
}
