.container-order-detail-asesor {
  height: calc(80vh + 100px);
}
.back-button {
  background: #3a98b7;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.back-button:hover {
  background: #327da3;
}
