/* UsersAdmin.css */

body {
  background-color: #282c34;
}

.table-container {
  max-height: calc(100vh - 210px);
  width: 100%;
  overflow-y: auto;
  border-collapse: collapse;
  border-radius: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}
