.Notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  transition: all 300ms ease;
}

.user {
  margin-bottom: 1rem;
  text-align: center;
  user-select: none;
}

.user > p {
  color: white;
  font-weight: bolder;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.user > span {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.title {
  font-weight: bold;
  color: #aa6752;
  font-size: 22px;
  user-select: none;
}

.updates-wrapper {
  width: 100%;
  padding: 0 1rem 2rem 1rem; /* Add padding as needed */
}

.loading,
.error {
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 2rem;
}

@media screen and (max-width: 1200px) {
  .Notifications {
    justify-content: flex-start;
    margin-top: 3rem;
  }
}

.options-notifications {
  width: 2rem;
  height: 2rem;
  filter: drop-shadow(0 0 0.5rem #111);
}
