.DashboardAdmin {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .DashboardAdmin {
    justify-content: flex-start;
    margin-top: 2rem;
  }
}
