.Cards {
  display: flex;
  gap: 10px;
  padding: 0 3rem 0 3rem;
}

.parentContainer {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .Cards {
    flex-direction: column;
  }
  .parentContainer {
    height: 9rem;
  }
}
